import React from 'react'
import { Link, graphql } from "gatsby"

import Layout from '../components/layout'

const BlogListPage = ({ data }) => (
  <Layout>
    <div className="index" id="blog">

    {data.allWordpressPost.edges && data.allWordpressPost.edges.map(({ node }) => (
      <div className="row mt-5 content" key={`blog-${node.wordpress_id}`}>
        <div className="col-lg-4 col-image">
          <Link to={`/blog/${node.slug}/`} title={node.title}>
            <img src={node.better_featured_image.source_url} className="img-fluid img-block" alt={node.title} />
          </Link>
        </div>
        <div className="col-lg-8 col-text">
          <h3 className="subtitle">{node.wps_subtitle}</h3>
          <h2 className="title primary my-2">{node.title}</h2>
          <div dangerouslySetInnerHTML={{ __html: node.excerpt }}></div>
          <p>
            <Link to={`/blog/${node.slug}/`} className="">Lees meer...</Link>
          </p>
        </div>
      </div>
    ))}

    </div>
  </Layout>
)

export default BlogListPage

export const query = graphql`
  {
    allWordpressPost {
      edges {
        node {
          id
          wordpress_id
          title
          wps_subtitle
          better_featured_image {
            source_url
          }
          excerpt
          slug
          link
        }
      }
    }
  }
`
